import { useEffect, useState } from 'react';

import { Typography } from '@airbus/components-react';

import styles from './index.module.css';

import { useLanguageContextAPI } from '../shared/context/LanguageContext';
import { useUserContextApi } from '../shared/context/UserContext';

import Layout from '../shared/components/layout';
import Spinners from '../shared/components/Spinner';
import ScreenTabs from '../features/landing_page/ScreenTabs';
import DisplayScreens from '../features/landing_page/DisplayScreens';

import { AccessData, ScreenData, UserData, UserDetailsData } from '../shared/types';
import { useAllScreensContext } from '../shared/context/AllScreensDetailsContext';

export default function Home() {
  const {
    loginDetails,
    accessControlDetails,
    userDetails,
    updateUserDetails,
    acceptDisclaimer,
  }: {
    loginDetails: UserData;
    accessControlDetails: AccessData;
    userDetails: UserDetailsData;
    updateUserDetails: React.Dispatch<UserDetailsData>;
    acceptDisclaimer: boolean;
  } = useUserContextApi();
  const { menuGroup } = useAllScreensContext();
  const { t } = useLanguageContextAPI();
  //   Screens State
  const [noScreenAccessDetails, setNoScreenAccessDetails] = useState<ScreenData>([]);
  const [screenAccessDetails, setScreenAccessDetails] = useState<ScreenData>([]);
  //   Tab State
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const allSubMenusWithParent = (input) => {
    return input.flatMap((screenData) => {
      return screenData.subMenu.map((subMenuData) => {
        return {
          parentAppName: screenData.appName,
          ...subMenuData,
        };
      });
    });
  };

  useEffect(() => {
    setLoading(false);
    if (accessControlDetails.length) {
      const accessibleScreens = accessControlDetails.map(
        (item) => item['mbdScreen'].screenInternalName,
      );

      if (menuGroup.length) {
        const filteredAccessScreens = menuGroup
          .map((item) => {
            const mergedSubMenu = item.subMenu.flatMap((item) => {
              return item.subChildMenu ? [item, ...item.subChildMenu] : [item];
            });
            const uniqueSubMenu = Array.from(
              new Map(mergedSubMenu.map((item) => [item.appNameId, item])).values(),
            );
            const filteredSubMenu = uniqueSubMenu.filter((subMenuItem) =>
              accessibleScreens.includes(subMenuItem?.appNameId),
            );
            return filteredSubMenu.length > 0 && { ...item, subMenu: filteredSubMenu };
          })
          .filter((item) => item);
        const updatedScreensAccess = allSubMenusWithParent(filteredAccessScreens);
        setScreenAccessDetails(updatedScreensAccess);

        const filteredNoAccessScreens = menuGroup
          .map((item) => {
            const mergedSubMenu = item.subMenu.flatMap((item) => {
              return item.subChildMenu ? [item, ...item.subChildMenu] : [item];
            });
            const uniqueSubMenu = Array.from(
              new Map(mergedSubMenu.map((item) => [item.appNameId, item])).values(),
            );
            const filteredSubMenu = uniqueSubMenu.filter(
              (subMenuItem) => !accessibleScreens.includes(subMenuItem.appNameId),
            );
            return filteredSubMenu.length > 0 && { ...item, subMenu: filteredSubMenu };
          })
          .filter((item) => item);
        const updatedNoScreensAccess = allSubMenusWithParent(filteredNoAccessScreens);
        setNoScreenAccessDetails(updatedNoScreensAccess);
      }
    }
  }, [accessControlDetails, menuGroup]);

  useEffect(() => {
    updateUserDetails({ ...userDetails, app_details: null });
  }, []);

  const renderScreenContent = () => {
    if (loading) return <Spinners />;
    if (!loginDetails.userId) return <Typography variant='h3'>{t('not_logged_in')}</Typography>;
    else if (!acceptDisclaimer)
      return <Typography variant='h6'>{t('landing_page.no_accept_disclaimer')}</Typography>;

    if (screenAccessDetails.length > 0)
      return (
        <DisplayScreens
          {...{
            selectedTab,
            noScreenAccessDetails,
            screenAccessDetails,
          }}
        />
      );
    else return <Typography variant='h6'>{t('landing_page.no_access')}</Typography>;
  };

  return (
    <Layout>
      <div className={styles.gridBox}>
        <ScreenTabs {...{ selectedTab, setSelectedTab, open, setOpen }} />
        {renderScreenContent()}
      </div>
    </Layout>
  );
}
